import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from './routes';

import NavTabs from '../components/nav-tabs';
import Disclaimer from './disclaimer';
import Faq from '../components/faq';
import Modal from '../components/modal';

import { calculatePoints } from '../utils/calculator';
import { getPrograms } from '../utils/programs';
import { getSubjects } from '../utils/subjects';
import { toTop } from '../utils/helpers';

import languages from '../data/languages.json';

const allSubjects = getSubjects();
const allPrograms = getPrograms();

function App() {
  const [program, setProgram] = useState(null);
  const [subjectMap, setSubjectMap] = useState({});
  const [addPoints, setAddPoints] = useState({});
  const [yob, setYob] = useState('');
  const [showCalc, setShowCalc] = useState(false);

  const [totals, setTotals] = useState({});

  const [showFaq, setShowFaq] = useState(false);

  const [disclaimerText, setDisclaimerText] = useState(null);
  const [showDisclaimer, setShowDisclaimer] = useState(false);

  // Store and restore calculator state
  useEffect(() => {
    window.isLoggedIn = false;
    async function fetchData() {
      const saved = window.sessionStorage.getItem('GRADE_CALC');
      let data = saved && JSON.parse(saved);

      let urlLoad = '';
      let urlCheck = '';
      if (window.location.hostname.indexOf('dev8.') !== -1) {
        urlLoad = 'http://dev8.min.utdanning.no/karakterkalkulator/load';
        urlCheck = 'http://dev8.data.utdanning.no/karakterkalkulator/check';
      }
      else if (window.location.hostname.indexOf('dev.') !== -1) {
        urlLoad = 'http://dev.min.utdanning.no/karakterkalkulator/load';
        urlCheck = 'http://dev.data.utdanning.no/karakterkalkulator/check';
      }
      else if (window.location.hostname.indexOf('beta.') !== -1) {
        urlLoad = 'https://beta.min.utdanning.no/karakterkalkulator/load';
        urlCheck = 'https://beta.data.utdanning.no/karakterkalkulator/check';
      }
      else {
        urlLoad = 'https://min.utdanning.no/karakterkalkulator/load';
        urlCheck = 'https://data.utdanning.no/karakterkalkulator/check';
      }

      const responseCheck = await fetch(urlCheck, { credentials: 'include' });
      const dataCheck = await responseCheck.json();
      if (dataCheck) {
        setDisclaimerText(dataCheck.disclaimer_text);
        setShowDisclaimer(dataCheck.show_disclaimer);
        window.isLoggedIn = dataCheck.logged_in;
      }

      if (!data) {
        const response = await fetch(urlLoad, { credentials: 'include' });
        const d2 = await response.json();
        if (Object.keys(d2).length) {
          data = d2;
        }
      }
      if (data) {
        // console.log('>> Restoring calculator state', data);
        setProgram(data.program || null);
        setSubjectMap(data.subjectMap || {});
        setAddPoints(data.addPoints || {});
        setYob(data.yob || '');
        setShowCalc(data.showCalc || false);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    if (showCalc) {
      const data = { program, subjectMap, addPoints, yob, showCalc };
      // console.log('<< Saving calculator state', data);
      window.sessionStorage.setItem('GRADE_CALC', JSON.stringify(data));
    }
  },
  [program, subjectMap, addPoints, yob, showCalc]);


  // Recalculate totals
  useEffect(() => {
    const rule235 = (Array.isArray(program) && program.indexOf('235') === 0) ? true : false;
    const newTotals = calculatePoints(subjectMap, addPoints, yob, rule235);
    setTotals(newTotals);
  },
  [program, subjectMap, addPoints, yob]);


  // Calculator
  const state1 = {
    program,
    subjectMap,
    addPoints,
    yob,
    totals,
    showCalc,
  };

  // Study selector
  const state2 = {
    subjectMap,
    yob,
    totals,
  };


  // Program choice

  const chooseProgram = (prog, sub, isIntroForm) => {
    if (!prog || prog === 'NONE') {
      setProgram([prog, sub]);
      return;
    }

    if (program && !isIntroForm) {
      const ok = window.confirm(
        'OBS: Karakterene du har lagt inn i Karakterkalkulatoren slettes når du bytter studieprogram eller programområde',
      );
      if (!ok) {
        return;
      }
    }

    const p1 = allPrograms[prog];
    const p2 = allPrograms[prog].programs[sub];

    const newSubjectMap = {
      common: p1.commonSubjects.reduce((a, s) => {
        if (allSubjects[s].type === 1 || allSubjects[s].type === 2) {
          a[s] = null;
        }
        return a;
      }, {}),
      commonProgram: p2.commonSubjects.reduce((a, s) => {
        if (allSubjects[s].type === 1 || allSubjects[s].type === 2) {
          a[s] = null;
        }
        return a;
      }, {}),
      optionalProgram: p2.optionalSubjects.reduce((a, s) => {
        if ((allSubjects[s].type === 1 || allSubjects[s].type === 2) && allSubjects[s].position) {
          a[s] = null;
        }
        return a;
      }, {}),
      vocational: {},
      special: JSON.parse(JSON.stringify(p1.specialSubjects)),
      language: (prog !== '235') ? JSON.parse(JSON.stringify(languages)) : null, // 23/5 skal ikke ha språk
    };

    for (const [, subj] of Object.entries(newSubjectMap.special)) {
      if (!subj.hasOwnProperty('selected')) {
        subj.selected = null;
      }
    }

    if (newSubjectMap.language && !newSubjectMap.language.hasOwnProperty('selected')) {
      newSubjectMap.language.selected = null;
    }

    setProgram([prog, sub]);
    setSubjectMap(newSubjectMap);
  };


  // Subject manipulation

  const addSubject = (section, id) => {
    const newSubjectMap = {
      ...subjectMap,
    };
    if (newSubjectMap?.[section]) {
      newSubjectMap[section][id] = null;
    }
    setSubjectMap(newSubjectMap);
  };

  const removeSubject = (section, ids) => {
    const newSubjectMap = {
      ...subjectMap,
    };
    if (newSubjectMap?.[section]) {
      if (section === 'language') {
        delete newSubjectMap.language.selected;
        delete newSubjectMap.language.value;
      }
      else if (section === 'special') {
        if (ids?.length) {
          ids.forEach(id => {
            if (newSubjectMap.special[id]) {
              delete newSubjectMap.special[id].selected;
              delete newSubjectMap.special[id].value;
            }
          });
        }


      }
      else if (ids?.length) {
        ids.forEach(id => {
          delete newSubjectMap[section][id];
        });
      }
    }
    setSubjectMap(newSubjectMap);
  };

  const chooseSubject = (section, id, choice) => {
    const newSubjectMap = {
      ...subjectMap,
    };
    if (section === 'language') {
      if (newSubjectMap.language) {
        newSubjectMap.language.selected = choice || null;
        delete newSubjectMap.language.value;
      }
    }
    else if (newSubjectMap[section]?.[id]) {
      newSubjectMap[section][id].selected = choice || null;
      delete newSubjectMap[section][id].value;
    }
    setSubjectMap(newSubjectMap);
  };


  // Subject score

  const setScore = (section, id, type, value) => {
    const getNewScore = score => {
      const newScore = score ? [...score] : [null, null];
      const idx = type === 'position' ? 0 : 1;
      newScore[idx] = value;
      if (newScore[0] === null && newScore[1] === null) {
        return null;
      }
      return newScore;
    };

    const newSubjectMap = {
      ...subjectMap,
    };

    if (section === 'language') {
      if (newSubjectMap.language) {
        newSubjectMap.language.value = getNewScore(newSubjectMap.language.value, type, value);
      }
    }
    else if (section === 'special') {
      if (newSubjectMap[section]?.[id]) {
        newSubjectMap[section][id].value = getNewScore(newSubjectMap[section][id].value, type, value);
      }
    }
    else {
      const sec = newSubjectMap[section];
      if (sec && sec[id] !== undefined) {
        sec[id] = getNewScore(sec[id], type, value);
      }
    }
    setSubjectMap(newSubjectMap);
  };


  // Calculator navigation

  const showCalculator = () => {
    setShowCalc(true);
    toTop();
  };

  const showFAQ = () => {
    setShowFaq(true);
  };

  // Calculator actions
  const actions = {
    chooseProgram,
    addSubject,
    removeSubject,
    chooseSubject,
    setScore,
    setAddPoints,
    setYob,
    showCalculator,
    showFAQ,
  };

  return (
    <div className="x-wrapper">
      <script async src="https://siteimproveanalytics.com/js/siteanalyze_6253897.js"></script>
      <Router basename="/karakterkalkulator">
        <header className="calculator-header">
          <Disclaimer disclaimerText={ disclaimerText } showDisclaimer={ showDisclaimer } />
          <NavTabs showFAQ={ showFAQ } />
        </header>
        <main>
          <Routes data={
            {
              state1: state1,
              state2: state2,
              actions: actions,
              showFAQ: showFAQ,
            }
          } />
        </main>
      </Router>
      <Modal title="Ofte stilte spørsmål" open={ showFaq } setOpen={ setShowFaq }>
        <Faq />
      </Modal>
    </div>
  );
}

export default App;
